import React from 'react';
import Container from '@material-ui/core/Container';
import LayoutPublic from '../components/layout/public';

export default function NotFoundPage() {
  return (
    <LayoutPublic>
      <Container component="main" maxWidth="md">
        <>
          404 - Página não encontrada
        </>
      </Container>
    </LayoutPublic>
  );
}